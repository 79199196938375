<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="pa-5">
      <v-radio-group v-model="form.loaLevel" row>
        <template v-slot:label>
          <div class="mr-2">Select LOA Level</div>
        </template>
        <v-radio
          label="Basic"
          value="LOABASIC"
          :disabled="!isNotActiveLOABASIC"
        ></v-radio>
        <v-radio
          label="Level 1"
          value="LOA1"
          :disabled="!isNotActiveLOA1"
        ></v-radio>
        <v-radio
          label="Level 1 Inc Agent"
          value="LOA1IncAgent"
          :disabled="!isNotActiveLOA1"
        ></v-radio>
        <v-radio
          label="Level 2"
          value="LOA2"
          :disabled="!isNotActiveLOA2"
        ></v-radio>
      </v-radio-group>
      <v-select
        v-model="form.addressId"
        :items="addressItems"
        item-text="value"
        item-value="id"
        label="Select Address"
        prepend-icon="mdi-office-building-marker"
        @change="selectAddress"
      ></v-select>
      <v-select
        label="Select Telephone Number"
        v-model="selectedContactNumber"
        prepend-icon="mdi-phone"
        :items="contactNumbers"
      ></v-select>
      <v-row class="mt-2" align="center">
        <v-col>
          <DataValue
            label="Customer Details"
            :value="
              `${selectedContact.title} ${selectedContact.firstName} ${selectedContact.lastName}`.trim()
            "
            :extra-info="selectedContact.jobTitle"
            icon="account"
          />
        </v-col>
        <v-col>
          <DataValue
            :label="
              selectedContactNumber === contactNumbers[0]
                ? 'Company Telephone'
                : 'Customer Telephone'
            "
            :value="selectedContactNumber"
            icon="phone"
          />
        </v-col>
        <v-col>
          <DataValue
            label="Company Details"
            :value="details.companyName"
            :extra-info="details.registrationNo"
            icon="office-building"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-1">
        <v-btn color="accent" text @click.stop="$emit('click-close')">
          Close
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          text
          :color="companyColor"
          :disabled="disabledSubmit || !form.addressId"
          @click="submitForm"
        >
          Send {{ formName }} to {{ selectedContact.emailAddress }}
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapActions, mapGetters } from "vuex"
import DataValue from "@/components/data-value"
import api from "@/store/api"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
export default {
  components: { DataValue },
  props: {
    formName: {
      type: String,
      default: ""
    },
    companyColor: {
      type: String,
      default: "grey"
    },
    disabledSubmit: {
      type: Boolean,
      default: true
    },
    selectedCompany: {
      type: String
    },
    selectedCampaign: {
      type: String
    }
  },
  data: () => ({
    valid: true,
    loa: {
      addressId: 0,
      customerName: "",
      position: "",
      company: "",
      customerEmail: "",
      customerPhone: "",
      empName: "",
      customerFName: "",
      // companyWithReg: "",
      employeeId: 0,
      companyId: 0,
      contactId: 0,
      widgetType: "",
      noteDescription: ""
    },
    form: {
      loaLevel: "",
      addressId: ""
    },
    widgets: [],
    contactNumbers: [],
    selectedContactNumber: null
  }),
  computed: {
    ...mapGetters("company", ["details", "companyId"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("addresses", ["addressItems", "selectedAddress"]),
    ...mapGetters("contacts", ["selectedContact", "primaryContactFullName"]),
    ...mapGetters("employee", [
      "userId",
      "employeeCampaign",
      "employeeDetails"
    ]),

    fullName() {
      return `${this.selectedContact.title} ${this.selectedContact.firstName} ${this.selectedContact.lastName}`
    },
    registrationNumber() {
      if (this.details.registrationNo) {
        return `${this.details.companyName} (Reg Number ${this.details.registrationNo})`
      }
      return `${this.details.companyName}`
    },
    customerPhone() {
      return `${this.selectedContactNumber}`
    },
    isNotActiveLOABASIC() {
      return (
        this.widgets.findIndex(
          x => x.saleType == this.saleTypeId && x.widgetType === "LOABASIC"
        ) > -1
      )
    },
    isNotActiveLOA2() {
      return (
        this.widgets.findIndex(
          x => x.saleType == this.saleTypeId && x.widgetType === "LOA2"
        ) > -1
      )
    },
    isNotActiveLOA1() {
      return (
        this.widgets.findIndex(
          x => x.saleType == this.saleTypeId && x.widgetType === "LOA1"
        ) > -1
      )
    },
    saleTypeId() {
      return this.selectedCompany === "utility hive" ? 2 : 1
    }

    // ...mapGetters("employee", ["selectedContact"])
  },
  created() {
    if (this.formEditMode) {
      Object.entries(this.details).forEach(
        mergeExistingEntriesIntoForm(this.form)
      )
    }
  },
  mounted() {
    if (this.details.telephone !== null && this.details.telephone !== "") {
      this.contactNumbers.push(this.details.telephone)
      this.selectedContactNumber = this.details.telephone
    }
    if (
      this.selectedContact.contactNumber !== null &&
      this.selectedContact.contactNumber !== ""
    ) {
      this.contactNumbers.push(this.selectedContact.contactNumber)
    }

    this.fetchWidgets()
  },
  methods: {
    ...mapActions("addresses", ["selectAddress"]),
    ...mapActions("notes", ["fetchNotes"]),

    async sendUHLoa() {
      const isUHCampaign = this.employeeCampaign.utilityHive
        ? "Utility Hive"
        : "Commercial Support Service"
      this.loa.noteDescription = `LOA Sent<br/>${isUHCampaign}<br/>${this.fullName}<br/>${this.selectedContact.emailAddress}`
      this.loa.addressId = this.selectedAddress.addressId
      this.loa.customerName = this.fullName
      this.loa.customerEmail = this.selectedContact.emailAddress
      this.loa.customerPhone = this.customerPhone
      this.loa.employeeId = this.userId
      this.loa.position = this.selectedContact.jobTitle
      this.loa.company = this.details.companyName
      this.loa.companyId = this.details.companyId
      this.loa.contactId = this.selectedContact.contactId
      this.loa.customerFName = this.selectedContact.firstName
      this.loa.customerLName = this.selectedContact.lastName
      this.loa.companyWithReg = this.registrationNumber
      this.loa.empName =
        this.employeeDetails.firstName + " " + this.employeeDetails.lastName
      this.loa.widgetType = this.form.loaLevel

      const res = await api.post("sendUHLoa", this.loa)
      if (res.status === 200) {
        changesSaved("LOA Sent Successfully!")
        this.loa = ""
        setTimeout(() => {
          this.fetchNotes()
          this.resetInitialData()
          this.$emit("click-close")
        }, 2000)
      } else {
        somethingWentWrong()
      }
    },
    async sendCssLoa() {
      const isUHCampaign = this.employeeCampaign.utilityHive
        ? "Utility Hive"
        : "Commercial Support Service"
      const noteDescription = `LOA Sent<br/>${isUHCampaign}<br/>${this.fullName}<br/>${this.selectedContact.emailAddress}`
      const loaDetails = {
        description: noteDescription,
        addressId: this.selectedAddress.addressId,
        customerName: this.fullName,
        customerEmail: this.selectedContact.emailAddress,
        customerPhone: this.customerPhone,
        employeeId: this.userId,
        position: this.selectedContact.jobTitle,
        company: this.details.companyName,
        companyId: this.details.companyId,
        contactId: this.selectedContact.contactId,
        customerFName: this.selectedContact.firstName,
        customerLName: this.selectedContact.lastName,
        companyWithReg: this.registrationNumber,
        empName:
          this.employeeDetails.firstName + " " + this.employeeDetails.lastName,
        widgetType: this.form.loaLevel
      }
      const res = await api.post("sendCSSLoa", loaDetails)
      if (res.status === 200) {
        changesSaved(`LOA Sent to ${this.fullName}`)
        this.loaDetails = ""
        setTimeout(() => {
          this.fetchNotes()
          this.resetInitialData()
          this.$emit("click-close")
        }, 2000)
      } else {
        somethingWentWrong()
      }
    },
    submitForm() {
      if (this.selectedCompany === "utility hive") {
        this.sendUHLoa()
      } else {
        this.sendCssLoa()
      }
    },
    async fetchWidgets() {
      const res = await api.get(`widgets`)
      if (res.status === 200) {
        this.widgets = res.data
      }
    },
    resetInitialData() {
      this.valid = true
      this.loa = {
        addressId: 0,
        customerName: "",
        position: "",
        company: "",
        customerEmail: "",
        customerPhone: "",
        empName: "",
        customerFName: "",
        employeeId: 0,
        companyId: 0,
        contactId: 0,
        widgetType: "",
        noteDescription: ""
      }
      this.form = {
        loaLevel: "",
        addressId: ""
      }
    }
  }
}
</script>
